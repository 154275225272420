/* eslint-disable import/first */
import React from "react";
import { motion } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";
import axios from "axios";

// components
import Main from "../../components/main";
import Header from "../../components/header";
import Seo from "../../components/seo";
import Footer from "../../components/footer";
import { ButtonCTADarkest, ButtonCTADarkestOutline } from "../../components/buttonCTA";

// styles
import {
  HeaderSpacer,
  ArticleTitle,
  ContentTitle,
  Title,
  ContentInfo,
  ContentInfoCTA,
  SubInfo,
  SubInfoDescription,
  ArticleInfo,
  Label,
  Input,
  Select,
  Form,
  SubmitForm,
  InputWithMask,
} from "../../styles/mentoria-pensamental-10x";

// components
function InputAndLabel({ onChange, value, htmlFor, inputType, label, placeholder, hasError, errorMessage }) {
  return (
    <div className="text-left mb-4">
      <Label htmlFor={htmlFor}>{label}</Label>

      <div className="mt-1 relative rounded-md shadow-sm">
        <Input $hasError={hasError} onChange={onChange} value={value} type={inputType} name={htmlFor} id={htmlFor} placeholder={placeholder} />
      </div>

      {hasError && <p className="mt-2 text-red-500 text-xs italic">{errorMessage}</p>}
    </div>
  );
}

function InputWithMaskAndLabel({ onChange, value, htmlFor, inputType, label, placeholder, hasError, errorMessage }) {
  return (
    <div className="text-left mb-4">
      <Label htmlFor={htmlFor}>{label}</Label>

      <div className="mt-1 relative rounded-md shadow-sm">
        <InputWithMask $hasError={hasError} mask="(99) 99999-9999" onChange={onChange} value={value} type={inputType} name={htmlFor} id={htmlFor} placeholder={placeholder} />
      </div>

      {hasError && <p className="mt-2 text-red-500 text-xs italic">{errorMessage}</p>}
    </div>
  );
}

function SelectAndLabel({ onChange, value, htmlFor, label, options, hasError, errorMessage }) {
  return (
    <div className="text-left mb-4">
      <Label htmlFor={htmlFor}>{label}</Label>

      <Select $hasError={hasError} onChange={onChange} value={value} name={htmlFor} id={htmlFor}>
        {options.map((option) => (
          <option value={option.value} key={option.label}>
            {option.label}
          </option>
        ))}
      </Select>

      {hasError && <p className="mt-2 text-red-500 text-xs italic">{errorMessage}</p>}
    </div>
  );
}

// steps
function StepBase({ children, isNavigatinBackwards }) {
  const initial = { x: isNavigatinBackwards ? -1000 : 1000 };

  return (
    <motion.div className="text-center w-full max-w-screen-md flex flex-col" transition={{ duration: 0.5, type: "spring", stiffness: 100 }} initial={initial} animate={{ x: 0 }}>
      {children}
    </motion.div>
  );
}

function StepMotivacao({ ctaHandler, isNavigatinBackwards }) {
  return (
    <StepBase isNavigatinBackwards={isNavigatinBackwards}>
      <ContentInfo>
        <motion.div initial={{ x: "100vw" }} animate={{ x: 0 }} transition={{ duration: 0.5, type: "spring" }}>
          <SubInfo>Motivação</SubInfo>
          <SubInfoDescription>
            Para pessoas determinadas que realmente entendem a importância de ter uma estratégia de desenvolvimento para destravar comportamentos sabotadores e que impedem um problema profundo de ser
            resolvido.
          </SubInfoDescription>
          <SubInfoDescription>
            O Pensamental surgiu com o propósito de agregar valor à população através de um acompanhamento 1:1 customizado, totalmente focado em resultados disruptivos. Menor demora, maior impacto.
          </SubInfoDescription>
          <SubInfoDescription>
            Trabalhando em cima das duas maiores forças negativas psíquicas, as crenças centrais limitantes e os comportamentos disfuncionais, que atrasam qualquer processo de conquista e crescimento
            individual. Nossa proposta é transbordar muito além de um plano de ação completo, aliando ele a um acompanhamento fiel e intenso.
          </SubInfoDescription>
          <SubInfoDescription>
            Desenvolvemos estratégias completas de reestruturação cognitiva (transformação de crenças), controle comportamental, inteligência emocional, autoconhecimento, autoconfiança, comunicação e
            autoestima, para um maior aproveitamento em construção de bem-estar, relacionamentos saudáveis, autopercepção positiva e crescimento profissional.
          </SubInfoDescription>
        </motion.div>
      </ContentInfo>

      <ContentInfoCTA $centered>
        <ButtonCTADarkestOutline className="w-full sm:w-1/5" onClick={ctaHandler}>
          COMEÇAR
        </ButtonCTADarkestOutline>
      </ContentInfoCTA>
    </StepBase>
  );
}

function StepDados({ formValues, formErrors, setFormProperties, formHasError, ctaForwardHandler, ctaGoBackHandler, isNavigatinBackwards }) {
  const handleMoveForward = (event) => {
    event.preventDefault();

    if (formHasError) return;

    ctaForwardHandler();
  };

  return (
    <StepBase isNavigatinBackwards={isNavigatinBackwards}>
      <ContentInfo>
        <SubInfo>Sobre Você...</SubInfo>
        <InputAndLabel
          onChange={(e) => setFormProperties("entryName", e.target.value)}
          value={formValues.entryName}
          hasError={formErrors.entryName.error}
          errorMessage={formErrors.entryName.message}
          inputType="text"
          htmlFor="entryName"
          placeholder="Ex: John Doe"
          label="Qual o seu nome completo?"
        />
        <InputAndLabel
          onChange={(e) => setFormProperties("entryEmail", e.target.value)}
          value={formValues.entryEmail}
          hasError={formErrors.entryEmail.error}
          errorMessage={formErrors.entryEmail.message}
          inputType="email"
          htmlFor="entryEmail"
          placeholder="Ex: johndoe@email.com"
          label="Qual o seu e-mail?"
        />
        <InputWithMaskAndLabel
          onChange={(e) => setFormProperties("entryWhatsapp", e.target.value)}
          value={formValues.entryWhatsapp}
          hasError={formErrors.entryWhatsapp.error}
          errorMessage={formErrors.entryWhatsapp.message}
          inputType="tel"
          htmlFor="entryWhatsapp"
          placeholder="Ex: (51) 99707-9543"
          label="Qual o seu WhatsApp?"
        />
        <SelectAndLabel
          onChange={(e) => setFormProperties("entrySalary", e.target.value)}
          value={formValues.entrySalary}
          htmlFor="receita"
          label="Qual é a sua receita mensal?"
          options={[
            {
              label: "Menos de R$2.000,00 mensais",
              value: "Menos de R$2.000,00 mensais",
            },
            {
              label: "R$2.000,00 - R$5.000,00 mensais",
              value: "R$2.000,00 - R$5.000,00 mensais",
            },
            {
              label: "R$5.000,00 - R$10.000,00 mensais",
              value: "R$5.000,00 - R$10.000,00 mensais",
            },
            {
              label: "R$10.000,00 - R$50.000,00 mensais",
              value: "R$10.000,00 - R$50.000,00 mensais",
            },
            {
              label: "Mais de R$50.000,00 mensais",
              value: "Mais de R$50.000,00 mensais",
            },
          ]}
        />
      </ContentInfo>

      <ContentInfoCTA>
        <ButtonCTADarkestOutline className="w-2/5 sm:w-1/5" onClick={ctaGoBackHandler}>
          VOLTAR
        </ButtonCTADarkestOutline>
        <SubmitForm $disabled={formHasError} className="w-2/5 sm:w-1/5" onClick={handleMoveForward}>
          CONTINUAR
        </SubmitForm>
      </ContentInfoCTA>
    </StepBase>
  );
}

function StepAboutWill({ formValues, formErrors, setFormProperties, formHasError, ctaForwardHandler, ctaGoBackHandler, isNavigatinBackwards }) {
  const handleMoveForward = (event) => {
    event.preventDefault();

    if (formHasError) return;

    ctaForwardHandler();
  };

  return (
    <StepBase isNavigatinBackwards={isNavigatinBackwards}>
      <ContentInfo>
        <SubInfo>Sobre a Mentoria...</SubInfo>
        <InputAndLabel
          onChange={(e) => setFormProperties("entryMotivation", e.target.value)}
          value={formValues.entryMotivation}
          hasError={formErrors.entryMotivation.error}
          errorMessage={formErrors.entryMotivation.message}
          inputType="text"
          htmlFor="entryMotivation"
          placeholder="Ex: Quero realizar a mentoria para..."
          label="Qual a motivação para fazer a mentoria?"
        />
        <InputAndLabel
          onChange={(e) => setFormProperties("entryDifficulty", e.target.value)}
          value={formValues.entryDifficulty}
          hasError={formErrors.entryDifficulty.error}
          errorMessage={formErrors.entryDifficulty.message}
          inputType="text"
          htmlFor="entryDifficulty"
          placeholder="Ex: As dificuldades que sinto são..."
          label="Quais são as suas dificuldades?"
        />
        <InputAndLabel
          onChange={(e) => setFormProperties("entryResults", e.target.value)}
          value={formValues.entryResults}
          hasError={formErrors.entryResults.error}
          errorMessage={formErrors.entryResults.message}
          inputType="text"
          htmlFor="entryResults"
          placeholder="Ex: Eu desejo atingir..."
          label="Quais resultados você deseja atingir?"
        />
        <InputAndLabel
          onChange={(e) => setFormProperties("entryBarrier", e.target.value)}
          value={formValues.entryBarrier}
          hasError={formErrors.entryBarrier.error}
          errorMessage={formErrors.entryBarrier.message}
          inputType="text"
          htmlFor="entryBarrier"
          placeholder="Ex: Meu maior obstáculo..."
          label="Qual o seu maior obstáculo atual?"
        />
      </ContentInfo>

      <ContentInfoCTA>
        <ButtonCTADarkestOutline className="w-2/5 sm:w-1/5" onClick={ctaGoBackHandler}>
          VOLTAR
        </ButtonCTADarkestOutline>
        <SubmitForm $disabled={formHasError} className="w-2/5 sm:w-1/5" onClick={handleMoveForward}>
          CONTINUAR
        </SubmitForm>
      </ContentInfoCTA>
    </StepBase>
  );
}

function StepAboutNotes({ formValues, formErrors, formHasError, isSubmitting, setFormProperties, ctaGoBackHandler, isNavigatinBackwards }) {
  return (
    <StepBase isNavigatinBackwards={isNavigatinBackwards}>
      <ContentInfo>
        <SubInfo>Para Finalizar...</SubInfo>
        <SelectAndLabel
          onChange={(e) => setFormProperties("entryAvailability", e.target.value)}
          value={formValues.entryAvailability}
          htmlFor="entryAvailability"
          label="Qual é a sua disponibilidade?"
          options={[
            {
              label: "Urgentemente",
              value: "Urgentemente",
            },
            {
              label: "Dentro de uma semana",
              value: "Dentro de uma semana",
            },
            {
              label: "Dentro de um mês",
              value: "Dentro de um mês",
            },
            {
              label: "Nos próximos meses",
              value: "Nos próximos meses",
            },
            {
              label: "Não estou disponível",
              value: "Não estou disponível",
            },
          ]}
        />
        <InputAndLabel
          onChange={(e) => setFormProperties("entryInfos", e.target.value)}
          value={formValues.entryInfos}
          hasError={formErrors.entryInfos.error}
          errorMessage={formErrors.entryInfos.message}
          inputType="text"
          htmlFor="entryInfos"
          placeholder="Ex: Gostaria de informar que..."
          label="Alguma informação extra importante?"
        />
        <InputAndLabel
          onChange={(e) => setFormProperties("entryInterest", e.target.value)}
          value={formValues.entryInterest}
          hasError={formErrors.entryInterest.error}
          errorMessage={formErrors.entryInterest.message}
          inputType="text"
          htmlFor="entryInterest"
          placeholder="Ex: Eu decidi participar por..."
          label="O que despertou seu interesse?"
        />
      </ContentInfo>

      <ContentInfoCTA>
        <ButtonCTADarkestOutline className="w-2/5 sm:w-1/5" onClick={ctaGoBackHandler}>
          VOLTAR
        </ButtonCTADarkestOutline>
        <SubmitForm $disabled={formHasError} form="mentoria-pensamental-10x" type="submit" className="w-2/5 sm:w-1/5">
          {isSubmitting ? "CARREGANDO..." : "FINALIZAR"}
        </SubmitForm>
      </ContentInfoCTA>
    </StepBase>
  );
}

function StepFailed({ startAgainHandler }) {
  return (
    <StepBase>
      <ContentInfo>
        <SubInfo>Hmmm, que estranho...</SubInfo>
        <Label>Identificamos um erro na submissão do formulário</Label>
        <StaticImage src="../../images/fail.png" alt="Error" placeholder="blurred" imgStyle={{ objectFit: "contain" }} height={250} />
      </ContentInfo>

      <ContentInfoCTA $centered>
        <ButtonCTADarkest onClick={startAgainHandler} className="w-full sm:w-2/5 text-center">
          COMECAR NOVAMENTE
        </ButtonCTADarkest>
      </ContentInfoCTA>
    </StepBase>
  );
}

function FormularioMentoria() {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [step, setStep] = React.useState(1);

  const [isNavigatinBackwards, setIsNavigatinBackwards] = React.useState(false);

  const [formValues, setFormValues] = React.useState({
    entryAvailability: "Urgentemente",
    entrySalary: "Menos de R$2.000,00 mensais",
    entryName: "",
    entryEmail: "",
    entryWhatsapp: "",
    entryMotivation: "",
    entryDifficulty: "",
    entryResults: "",
    entryBarrier: "",
    entryInfos: "",
    entryInterest: "",
  });

  const [formErrors, setFormErrors] = React.useState({
    entryAvailability: { error: false, message: "" },
    entrySalary: { error: false, message: "" },
    entryName: { error: false, message: "" },
    entryEmail: { error: false, message: "" },
    entryWhatsapp: { error: false, message: "" },
    entryMotivation: { error: false, message: "" },
    entryDifficulty: { error: false, message: "" },
    entryResults: { error: false, message: "" },
    entryBarrier: { error: false, message: "" },
    entryInfos: { error: false, message: "" },
    entryInterest: { error: false, message: "" },
  });

  const [formStepDadosHasError, setFormStepDadosHasError] = React.useState(true);

  const [formStepWillHasError, setFormStepWillHasError] = React.useState(true);

  const [formStepNotesHasError, setFormStepNotesHasError] = React.useState(true);

  const moveToStep = (stepParam, isNavigatinBackwardsParam) => {
    setStep(stepParam);
    setIsNavigatinBackwards(isNavigatinBackwardsParam);
  };

  const validateFormValue = (name, value) => {
    const IS_EMPTY_STANDARD = /^$/g;
    const IS_MORE_THAN_255 = value?.length > 255;
    const IS_EMAIL_STANDARD = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const IS_PHONE_STANDARD = /(?:\()[0-9]{2}(?:\))\s?[0-9]{4,5}(?:-)[0-9]{4}/g;

    const IS_FIELD_ANY = ["entryName", "entryEmail", "entryWhatsapp", "entryMotivation", "entryDifficulty", "entryResults", "entryBarrier", "entryInfos", "entryInterest"].includes(name);
    const IS_FIELD_EMAIL = ["entryEmail"].includes(name);
    const IS_FIELD_WHATSAPP = ["entryWhatsapp"].includes(name);

    if (IS_FIELD_EMAIL) {
      if (!new RegExp(IS_EMAIL_STANDARD).test(value)) {
        setFormErrors({ ...formErrors, [name]: { error: true, message: "Digite um e-mail válido." } });
        return;
      }

      setFormErrors({ ...formErrors, [name]: { error: false, message: "" } });
    }

    if (IS_FIELD_WHATSAPP) {
      if (!new RegExp(IS_PHONE_STANDARD).test(value)) {
        setFormErrors({ ...formErrors, [name]: { error: true, message: "Digite um número válido." } });
        return;
      }

      setFormErrors({ ...formErrors, [name]: { error: false, message: "" } });
    }

    if (IS_FIELD_ANY) {
      if (new RegExp(IS_EMPTY_STANDARD).test(value)) {
        setFormErrors({ ...formErrors, [name]: { error: true, message: "Digite algum valor." } });
        return;
      }

      if (IS_MORE_THAN_255) {
        setFormErrors({ ...formErrors, [name]: { error: true, message: "Digite no máximo 255 caracteres." } });
        return;
      }

      setFormErrors({ ...formErrors, [name]: { error: false, message: "" } });
    }
  };

  const formItemHasError = (name, value) => {
    const IS_EMPTY_STANDARD = /^$/g;
    const IS_MORE_THAN_255 = value?.length > 255;
    const IS_EMAIL_STANDARD = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const IS_PHONE_STANDARD = /(?:\()[0-9]{2}(?:\))\s?[0-9]{4,5}(?:-)[0-9]{4}/g;

    const IS_FIELD_ANY = ["entryName", "entryEmail", "entryWhatsapp", "entryMotivation", "entryDifficulty", "entryResults", "entryBarrier", "entryInfos", "entryInterest"].includes(name);
    const IS_FIELD_EMAIL = ["entryEmail"].includes(name);
    const IS_FIELD_WHATSAPP = ["entryWhatsapp"].includes(name);

    if (IS_FIELD_EMAIL) {
      if (!new RegExp(IS_EMAIL_STANDARD).test(value)) {
        return true;
      }

      return false;
    }

    if (IS_FIELD_WHATSAPP) {
      if (!new RegExp(IS_PHONE_STANDARD).test(value)) {
        return true;
      }

      return false;
    }

    if (IS_FIELD_ANY) {
      if (new RegExp(IS_EMPTY_STANDARD).test(value)) {
        return true;
      }

      if (IS_MORE_THAN_255) {
        return true;
      }

      return false;
    }
  };

  const setFormProperties = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
    validateFormValue(name, value);
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();

    if (formStepDadosHasError || formStepNotesHasError || formStepWillHasError) {
      return;
    }

    setIsSubmitting(true);

    const replacedEntryAvailability = formValues.entryAvailability.replace(/\s/g, "+");
    const replacedEntrySalary = formValues.entrySalary.replace(/\s/g, "+");
    const GOOGLEFORMURL = `https://docs.google.com/forms/d/e/1FAIpQLSerT-KYHiOAMqvUdpR9TG0YNGMyO_PqUcLFiu7KByVMW2lbjw/formResponse?usp=pp_url&entry.1803536768=${formValues.entryName}&entry.193989842=${formValues.entryEmail}&entry.961105102=${formValues.entryWhatsapp}&entry.560155880=${formValues.entryMotivation}&entry.1234195425=${formValues.entryDifficulty}&entry.1072928330=${replacedEntrySalary}&entry.1217255123=${formValues.entryResults}&entry.1621772300=${formValues.entryBarrier}&entry.371670237=${formValues.entryInfos}&entry.624319409=${replacedEntryAvailability}&entry.163769935=${formValues.entryInterest}&submit=Submit`;
    const populateForm = fetch(GOOGLEFORMURL, {
      method: "GET",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    const SLACKPOSTURL = "https://hooks.slack.com/services/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";
    const MESSAGE = `
    Acabamos de receber uma nova resposta em nosso formulário da Mentoria 10x:
    - Disponibilidade: ${formValues.entryAvailability}
    - Salário: ${formValues.entrySalary}
    - Nome: ${formValues.entryName}
    - Email: ${formValues.entryEmail}
    - WhatsApp: ${formValues.entryWhatsapp}
    - Motivação: ${formValues.entryMotivation}
    - Dificuldades Enfrentadas: ${formValues.entryDifficulty}
    - Resultados que quero: ${formValues.entryResults}
    - Maiopr Obstáculo: ${formValues.entryBarrier}
    - Extra Info: ${formValues.entryInfos}
    - Despertou Interesse: ${formValues.entryInterest}
  `;

    Promise.all([populateForm])
      .then(async (res) => {
        const sendSlack = await axios.post(SLACKPOSTURL, JSON.stringify({ text: MESSAGE }), {
          withCredentials: false,
          transformRequest: [
            (data, headers) => {
              delete headers.post["Content-Type"];
              return data;
            },
          ],
        });
        if (sendSlack.status === 200) {
          window.location.replace(`/mentoria-pensamental-10x/confirmacao`);
        } else {
          throw Error();
        }
      })
      .catch(() => {
        moveToStep(5, false);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  React.useEffect(() => {
    const formStepDadosHasErrorInternal = Object.entries(formValues)
      .filter((formValue) => ["entryName", "entryEmail", "entryWhatsapp", "entrySalary"].includes(formValue[0]))
      .map((formValue) => formItemHasError(formValue[0], formValue[1]))
      .some((error) => error);
    setFormStepDadosHasError(formStepDadosHasErrorInternal);

    const formStepWillHasErrorInternal = Object.entries(formValues)
      .filter((formValue) => ["entryMotivation", "entryDifficulty", "entryResults", "entryBarrier"].includes(formValue[0]))
      .map((formValue) => formItemHasError(formValue[0], formValue[1]))
      .some((error) => error);
    setFormStepWillHasError(formStepWillHasErrorInternal);

    const formStepNotesHasErrorInternal = Object.entries(formValues)
      .filter((formValue) => ["entryAvailability", "entryInfos", "entryInterest"].includes(formValue[0]))
      .map((formValue) => formItemHasError(formValue[0], formValue[1]))
      .some((error) => error);
    setFormStepNotesHasError(formStepNotesHasErrorInternal);
  }, [formValues]);

  return (
    <>
      <Seo title="| Inscrição Mentoria Pensamental 10x" />
      <Main className="z-30">
        <Header />
        <HeaderSpacer />

        <Form id="mentoria-pensamental-10x" onSubmit={handleSubmitForm}>
          <ArticleTitle>
            <ContentTitle>
              <Title>Inscrição Mentoria Pensamental 10x</Title>
            </ContentTitle>
          </ArticleTitle>

          <ArticleInfo>
            {step === 1 && <StepMotivacao isNavigatinBackwards={isNavigatinBackwards} ctaHandler={() => moveToStep(2, false)} />}

            {step === 2 && (
              <StepDados
                formValues={formValues}
                formErrors={formErrors}
                setFormProperties={setFormProperties}
                formHasError={formStepDadosHasError}
                isNavigatinBackwards={isNavigatinBackwards}
                ctaGoBackHandler={() => moveToStep(1, true)}
                ctaForwardHandler={() => moveToStep(3, false)}
              />
            )}

            {step === 3 && (
              <StepAboutWill
                formValues={formValues}
                formErrors={formErrors}
                setFormProperties={setFormProperties}
                formHasError={formStepWillHasError}
                isNavigatinBackwards={isNavigatinBackwards}
                ctaGoBackHandler={() => moveToStep(2, true)}
                ctaForwardHandler={() => moveToStep(4, false)}
              />
            )}

            {step === 4 && (
              <StepAboutNotes
                formValues={formValues}
                formErrors={formErrors}
                setFormProperties={setFormProperties}
                isSubmitting={isSubmitting}
                formHasError={formStepNotesHasError}
                isNavigatinBackwards={isNavigatinBackwards}
                ctaGoBackHandler={() => moveToStep(3, true)}
              />
            )}

            {step === 5 && <StepFailed startAgainHandler={() => moveToStep(2, true)} />}
          </ArticleInfo>
        </Form>

        <Footer isDarkest />
      </Main>
    </>
  );
}

export default FormularioMentoria;
